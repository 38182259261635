@import '@fontsource/gothic-a1';

.toaster {
  max-width: 95%;
  width: max-content;
  word-break: break-all;
}
.loader{
  z-index: 1000;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  gap: 20px;
  display: grid;
}
.carnft-font{
  font-family: 'Gothic A1';
  font-weight: 900;
  -webkit-text-stroke-width: 0.2px; 
}
.carnft-font.panel-title{
  color: #3642AB;
  font-size: 24px;
  letter-spacing: 0.72px; 
  -webkit-text-stroke-width: 0.2px; 
  margin-top: 5px;
}

.carnft-font.title{
  color: #000;
  font-size: 14px;
  letter-spacing: 0.84px;
}

.carnft-font.description{
  color: #000;
  font-size: 14px;
  letter-spacing: 0.84px;
  min-width: 100px;
}

.carnft-font.description.privacy-policy{
  font-size: 16px;
}


.vc-img{
  background-position: center;
  background-repeat: no-repeat;
  height: 380px;
}

.line{
  font-family: 'Gothic A1';
  font-weight: 900;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #06C755;
  color: #FFFFFF;
  width: 150px;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  font-size: 16px;

  justify-content: space-around;
  padding: 2px 5px
}

.line:hover{
  opacity: 0.8;
}

.text-stroke-custom{
  -webkit-text-stroke-width: thin;
}
@media screen and (min-width: 500px) {
  .toaster {
    max-width: 837px;
    word-break: break-all;
  }
}
@media screen and (max-width: 415px) {
  .carnft-font.panel-title{
    font-size: 20px;
  }
}
@media screen and (max-width: 365px) {
  .carnft-font.description{
    min-width: 70px;
  }
}
